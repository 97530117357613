<template>
  <div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="top">
            <div class="top-title">Automatic loan</div>
            <div class="tips">When you liquidate the order we will</div>
            <div class="tips">automatically apply for the next loan for</div>
            <div class="tips">you to this product, and you can receive</div>
            <div class="tips">the loan more quickly</div>
            <div class="loan-title">Estimated amount for automatic loan</div>
            <div class="loannum">₹{{ settledAutoApplyAmount?dataMoney(settledAutoApplyAmount):"0" }}</div>
             <div class="account-list">
              Receiving account
             </div>
            <div class="account-box">
              <div class="account-left">
                Bank name
              </div>
              <div class="account-right">
                {{ data? data.bankName:'' }}
              </div>
            </div>
            <div class="account-box">
              <div class="account-left">
                IFSC
              </div>
              <div class="account-right">
                {{ data? data.bankCode:'' }}
              </div>
            </div>
            <div class="account-box">
              <div class="account-left">
                Bank Account No
              </div>
              <div class="account-right">
                {{ data? data.bankCard:'' }}
              </div>
            </div>
            <div class="bottom-tip" style="margin-top: 0.5333rem;">
              The pass rate of automatic loan application 
            </div>
            <div class="bottom-tip">is up to <span style="font-size: 0.3467rem;color:#005AF1 ;">95%</span></div>
            <!-- <van-button type="primary" class="bottom-btn" v-if="showBtn" @click="automaticLoan">Repayment and open the <br>automatic loan</van-button> -->
            <van-button type="primary" class="bottom-btn1"  v-if="showBtn" @click="RepaymentOnly">Repayment Now</van-button>
            <div style="display: flex;margin-top: 0.4533rem;" @click="clickChecked">
            <van-checkbox v-model="checked">
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
            </van-checkbox>
            <div style="font-weight: 400;color: #8F99A3;font-size: 0.3467rem;margin-left:0.1067rem" v-if="checked">Automatic loan enabled</div>
            <div style="font-weight: 400;color: #8F99A3;font-size: 0.3467rem;margin-left:0.1067rem" v-else>Auto reapply loan without waiting</div>
          </div>
          
          </div>
          <img  src="@/assets/offbtn.png" alt="" @click="entiendo" class="close-icon" />
        </div>
        
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { dataMoney } from '@/utils/money'
import { getBankCard,getSettledAutoApply} from "@/views/normalExtension/lib.js"
export default {
  data () {
    return {
      show: false,
      showBtn:null,
      data:null,
      checked:true,
      url:null,
      loadingBtn:null,
      orderNo:null,
      settledAutoApplyAmount:null,
      activeIcon: require('@/assets/on.png'),
      inactiveIcon: require('@/assets/cherkoff.png'),
    }
  },
  methods: {
    dataMoney,
    entiendo () {
      this.show = false
    setTimeout(()=>{
      this.checked=true
    },500)
    },
    async getBankCard(){
      this.data=null
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
   const res=await getBankCard()
   console.log(res,'银行卡信息');
   if(res.arrival==='200'){
    
    this.data=JSON.parse(res.brake[0].brake)
   if( this.data){
    loadingInstance.clear()
   }
    console.log(this.data,'data');
   }else{
    loadingInstance.clear()
   }
   
  },
async automaticLoan(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
  if (this.loadingBtn) return
      this.loadingBtn = true

    const res=await getSettledAutoApply({
      type:this.checked,
      // orderNo:'2024020415501093585951' //170状态 放款成功
      orderNo:this.orderNo, //180状态 放款成功
     })
     if(res.arrival==='200'){
      loadingInstance.clear()
      this.show = false
      this.$router.push({ name: 'easyPay', params: { url:this.url,name:'normalExtension' } })
      // window.location.href=this.url
     }
     setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定

      
  },
  clickChecked(){
    this.checked=!this.checked
  },
 async RepaymentOnly(){
    const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    if (this.loadingBtn) return
      this.loadingBtn = true
      const res= await getSettledAutoApply({
      type:this.checked,
      // orderNo:'2024020415501093585951' //170状态 放款成功
      orderNo:this.orderNo, //180状态 放款成功
     })
     if(res.arrival==='200'){
      loadingInstance.clear()
     }
     this.show = false
      this.$router.push({ name: 'easyPay', params: { url:this.url } })
    setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
  }


  },
  mounted(){
    // this.getBankCard()
  }

}
</script>

<style lang="less" scoped>
.van-overlay{
  z-index: 999;
}
.img-icon{
  height: 0.4267rem;
  width: 0.4267rem;
}
.wrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
 
  .block {
    width: 8.72rem;
    height: auto;
    position: relative;
    background: #F6F7F5;
    display: flex;
    flex-direction: column;
    padding: 0.8533rem 0.4267rem 0.6667rem 0.4267rem;
    border-radius: 1.0667rem;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-title {
        color: #000000;
        font-size: 0.5333rem;
        font-weight: 700;
        margin-bottom: 0.4rem;
      }
      .tips{
        font-size: 0.3733rem;
        color: #8F939B;
      }
      .loan-title{
        margin-top: 0.8267rem;
        color: #000000;
        font-size: 0.4267rem;
      }
      .loannum{
        margin-bottom: 0.6133rem;
        margin-top: 0.1333rem;
        font-size: 0.7467rem;
        font-weight: 700;
        color: #000000;

      }
      .account-list{
        width: 100%;
        margin-bottom: 0.3467rem;
        text-align: initial;
        font-size: 0.3733rem;
        color: #000000;
      }
      .account-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom:0.4267rem;
        .account-left{
          font-size: 0.3733rem;
          color: #8F939B;
        }
        .account-right{
          width: 4.5rem;
            text-align: right;
            word-wrap: break-word; /* 使用 word-wrap */
          font-size: 0.3733rem;
          color: #000000;
          font-weight:600;
        }
      }
      .bottom-tip{
        font-size:0.3467rem;
        color: #8F939B;
      }
      .bottom-btn{
        margin-top:0.5067rem;
        width:6.6933rem;
         height:1.5733rem;
         font-weight: 500;
         font-size:0.48rem;
         color: #FFFFFF;
         background: #005AF1;
         border-radius:0.8rem;
         border: none;
      }
      .bottom-btn1{
        margin-top: 0.32rem;
        width:6.6933rem;
         height:1.5733rem;
         font-size: 0.48rem;
         color: #FFFFFF;
         font-weight: 500;
         background: #005AF1;
         border-radius:0.8rem;
         border: none;
      }

    }

  }



  .close-icon{
    width: 0.32rem;
    height: 0.32rem;
    // margin-top: 1.4133rem;
    border: none;
    top: 0.5333rem;
    right:0.5333rem;
    position: absolute;
  }
}</style>
