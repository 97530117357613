<template>
  <div>
    <van-nav-bar
      :title="title"
      @click-left="goBack">
      <span class="left" slot="left">
        <van-icon name="arrow-left"  class="icon"/>
      </span>
      </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'heaDer',
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      show: null
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped lang="less">
.van-nav-bar {
  height: 3.2rem;
  padding-top:1.333rem;
  background: #000000;
 ::v-deep .van-nav-bar__title {
    color:#ffffff;
    font-size: 0.48rem;
  }
  .left {
    display: flex;
    align-items: center;
    .icon{
      font-size: 0.5rem;
      color:#ffffff;
    }
  }
}
</style>
