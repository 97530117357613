import Request, { API } from '@/http/index.js'

// 获取订单详情
export async function getOrderDetails (data) {
  const res = await Request.getData(API.order.dataDetails+ `?housing=${data.orderNo}&pack=${data.pageType}`)
  return res
}
//获取客服电话
export async function getCustomerServiceInfo () {
  const res = await Request.getData(API.customer.CustomerPhone)
  return res
}
//用户意见反馈按钮
export async function getcomplaint (data) {
  const res = await Request.getData(API.customer.Complaint,{
      content: data.content,
      title: data.title
  })
  return res
}