import Request, { API } from '@/http/index.js'
// 获取订单详情
export async function getOrderDetails (data) {
  const res = await Request.getData(API.order.dataDetails+ `?housing=${data.orderNo}&pack=${data.pageType}`)
  return res
}

// 展期订单详情
export async function getDelayFlexble (data) {
  const res = await Request.getData(API.order.DelayFlexble,{
    rather: data.delayTerm,
    housing: data.orderNo,
    together: data.termUnit
  })
  return res
}

//还款详情
export async function getRepaymentDetail (data) {
  const res = await Request.getData(API.order.RepaymentDetail,{
    rather: data.delayTerm,
    housing: data.orderNo,
    can:data.requestType
  })
  return res
}
//更新订单utr接口
export async function getUpOrderUtr (data) {
  const res = await Request.getData(API.order.UpOrderUtr+ `?orderNo=${data.orderNo}&utr=${data.utr}&amount=${data.amount}&img=${data.img}`)
  return res
}