import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/flexible'
import VConsole from 'vconsole'
import Vant from 'vant';
import javaGet from '@/utils/javaGet'
import managementEvents from '@/utils/managementEvents'
import 'vant/lib/index.css';
Vue.prototype.$javaGet = javaGet
Vue.use(Vant)
Vue.use(managementEvents)
Vue.config.productionTip = false
const isDev = process.env.NODE_ENV === 'development'
isDev && new VConsole()

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

// javaGet('6', getData)