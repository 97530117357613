export default {
  managementEvents:"/governor/limit/empire/",//打点
  order:{
    dataDetails:'/governor/integration/accusation', //订单详情
    AutoApply:'/governor/integration/gesture', //是否展示自动复借按钮
    SettledAutoApply:'/governor/integration/execution', //是否自动复借
    RepaymentDetail:'/governor/integration/gift', //还款详情
    UpOrderUtr:'/api/order/up-order-utr', //更新订单utr
    DelayFlexble:'/governor/turn/imply', //展期订单详情
    BankCard:'/governor/owner/excuse' //获取银行卡列表
  },
  customer:{
    CustomerPhone:'/api/common/customerServiceInfo',//获取客服电话
    Complaint:'/api/user/complaint'//获取客服电话
  }
}