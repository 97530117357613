<template>
  <div class="content">
    <div class="headers">
      <van-icon name="arrow-left" @click="go"  class="icon"/>
    </div>
    <div class="content-box">
      <div class="title" :style="{ backgroundImage:backgroundStyle ? 'url(' + backgroundStyle + ')':''  }">
      <van-skeleton title avatar  :loading="loading">
      <img src="@/assets/auditingIcon.png" alt="" style="width: 1.2533rem;height:1.2533rem;" v-if="status==='90'">
      <img src="@/assets/approvedIcon.png" alt="" style="width: 1.2533rem;height:1.2533rem;" v-if="status==='100'">
      <img src="@/assets/issuingIcon.png" alt="" style="width: 1.2533rem;height:1.2533rem;" v-if="status==='150'">
      <img src="@/assets/issuedicon.png" alt="" style="width: 1.2533rem;height:1.2533rem;" v-if="status==='170'">
    </van-skeleton>
      <div v-if="status==='90'">Order auditing</div>
      <div  v-if="status==='100'">Order approved</div>
      <div  v-if="status==='150'">Order issuing</div>
      <div  v-if="status==='170'">Order issued</div>
    </div>
    <div class="Details">
      <div class="Details-name">
        <div class="Details-left">
          <van-skeleton title avatar  :loading="loading">
          <img :src="img" alt="" style="width: 1.0667rem;height: 1.0667rem;margin-right:0.5067rem;">
        </van-skeleton>
          <div style="font-size:0.48rem;color: #000;font-weight: 600">{{ productName }}</div>
        </div>
        <div style="color: #000000;color:rgba(0,0,0,0.4);font-size:0.48rem;">Loan Details</div>
      </div>
      <div class="Details-box">
        <div class="box-line">
        </div>
        <div class="box-num">
          <div class="numBox" v-for="(item,index) in list" :key="index">
            <div class="num-left">{{ item.strip }}</div>
            <div class="num-right" v-html="item.increased"></div>
          </div>
        </div>
      </div>
     <div class="loan-box"  v-if="status==='170'">
      <div class="loan-contract" @click="loan('Acuerdo de préstamo')">
        <div class="loan-name">See loan contract</div>
        <img src="@/assets/rightOne.png" alt="" style="width: 0.16rem;height: 0.2667rem;">
      </div>
     </div>
     <div class="bottom-btn" v-if="status==='170'" @click="gonormalExtension">
      Repay
    </div>
    </div>
    
    <agreeMent class="agreeMent"></agreeMent>
    </div>
   
  </div>
</template>

<script>
import { Toast } from 'vant';
import agreeMent from '@/components/agreeMent.vue'
import { base64ToImage } from '@/utils/baseImg'
import {getOrderDetails} from '@/views/order/lib'
export default {
name:'orDer',
components:{agreeMent},

data(){
 return{
  title:"orDer",
    list:[],
    productName:'',
    img:"",
    orderNo:localStorage.getItem('orderNo')||'',
    status:null,
    loading:true,
    backgroundUrls: {
        90: require('@/assets/auditingBg.png'),
        100: require('@/assets/approvedBG.png'),
        150: require('@/assets/issuingBG.png'),
        170: require('@/assets/issuedBG.png')
      },
 }
},
computed: {
    // 根据当前状态动态决定背景图 URL
    backgroundStyle() {
      return this.backgroundUrls[this.status];
    }
  },

methods: {
 async getOrderDetails(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    const res=await getOrderDetails({
      pageType:1,
     
      orderNo:this.orderNo //170状态 放款成功
    })
    console.log(res,'订单详情');
  if(res.arrival==='200'){
    loadingInstance.clear()
    this.loading=false,
    this.list=res.brake.grin
    this.productName=res.brake.midnight
    this.img = base64ToImage(`${'data:image/png;base64,' + res.brake.beat}`)
    this.status=res.brake.badly
    if (this.status === '90') {
      this.type = 'AUDITING'
    }else if (this.status === '100') {
      this.type = 'APPROVED'
    }  else if (this.status === '150') {
      this.type = 'ISSUING'
    }else if (this.status === '170') {
      this.type = 'ISSUED'
    } 
     this.$managementEvents({ pageName: 'try', action: 'assure', extInfo: {  type: this.type, loanOrderId: this.orderNo } })
  }
  },
  go(){
    console.log('点击了');
   this.$javaGet('returnH5')
  },
  //去到期还款页面
  gonormalExtension(){
    this.$router.replace('normalExtension')
  },
  // 借款协议
  loan(name){
    this.$router.push({ name: 'AgreeMent', query: { name } })
  }
  

  },
  mounted(){
    this.getOrderDetails()
   
  }
}
</script>

<style lang="less" scoped>

.content{
  // width: 100%;
  height:100%;
  background: #F6F7F5;
  display: flex;
  padding-top: 3.1467rem;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  position: relative;
 
  .headers{
    position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
    width: 100%;
    background: #F6F7F5;
    height: 2.9333rem;
    padding: 0.96rem 0.3467rem 0 0.6667rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .icon{
      font-size: 0.5rem;
    }
  }
  .content-box{
    width: 100%;
  height: auto;
  // margin-top:3.1467rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .agreeMent{
   margin:1.3333rem 0;
  }
    .title{
    width:10rem;
    height:3.3867rem /* 127/37.5 */;
    // background: #090A0D;
    color: #FFFFFF;
    font-size: 0.5333rem;
    display: flex;
    background-size:100% 100%;
    justify-content:space-between;
    align-items:center;
    border-radius: 0.7467rem;
    padding: 0 1.28rem 0.3667rem 1.1733rem;
  }
  .Details{
    position: relative;
    width:9.1467rem;
    padding: 0.5067rem 0.4267rem 0.2667rem  0.4267rem;
    background: #FFFFFF;
    border-radius:0.5333rem;
    .Details-name{
      margin-bottom:0.4533rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Details-left{
      display: flex;
      align-items: center;
      }
    }
    .Details-box{
      display: flex;
      
      flex-direction: column;
     
      .box-line{
        width: 8.72rem;
        height: 0.0267rem;
        background: #E7E7E7;
        margin-bottom:0.6133rem;
      }
      .box-num{
        display: flex;
        flex-direction: column;
        .numBox{
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3733rem;
          .num-left{
            width: 4.5rem;
            text-align: left;
            font-weight: 400;
            color: rgba(0,0,0,0.4);
            font-size: 0.34267rem;
          }
          .num-right{
            width: 4.5rem;
            text-align: right;
            word-wrap: break-word; /* 使用 word-wrap */
            font-size: 0.3733rem;
            color: #000000;
            font-weight: 600;
          }
        }
      }
    }
    .loan-box{
      display: flex;
      justify-content: center;
      margin-bottom: 1.44rem;
      .loan-contract{
      width: 3.7867rem;
      height:0.9067rem;
      border-radius: 0.4533rem;
      padding: 0 0.2rem;
      border: 0.0267rem solid #BFBAB5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .loan-name{
        font-size: 0.3733rem;
        color: rgba(0,0,0,0.4);
        font-weight: 400;
      }
    }
    }
    .bottom-btn{
          bottom:-0.8rem;
          position: absolute;
          left: 0;
          right: 0;
          margin-top: 0.5333rem;
          width:6.6933rem;
          height:1.5733rem;
          background: #000000;
          border-radius: 0.8rem;
          box-shadow: 0 0.16rem 0.64rem 0 rgba(22,23,48,0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size:0.4267rem;
          margin: auto;
          color: #ffffff;
       }
   
  }
  }
  
 

}

</style>