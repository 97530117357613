<template>
  <div class="content">
    <div class="headers">
      <div class="headers-title">
  <img src="@/assets/left.png" alt="" style="width:0.2133rem;height:0.3733rem;" @click="go">
  <!-- <div style="font-size: 0.5333rem;font-weight: bold;color: #ffffff;">Repay</div> -->
      </div>
     
    </div>
    <div class="mian-box">
      <div class="box-top">
       <div class="box-title">
         <div class="title-left">
          <van-skeleton title avatar  :loading="loading">
          <img :src="img" alt="" style="width: 1.0667rem;height: 1.0667rem;margin-right: 0.32rem;">
        </van-skeleton>
         <div style="color: #000000;font-size:0.48rem;font-weight:700;">{{ productName }}</div>
        </div>
         <div class="title-right">
          <img src="@/assets/rightpay.png" v-if= !showingImage alt="" style="height:0.32rem;width:0.2133rem;margin-left: 0.1067rem;" @click="showBox">
          <img src="@/assets/bottom.png" v-else alt="" style="height:0.2133rem;width:0.33rem;margin-left: 0.1067rem;" @click="showBox">
         </div>
       </div>
       <div class="box-center">
          <div class="right-name"> Extension fee</div>
         <div class="center-right">
          <div style="font-size:0.64rem;color: #000000;font-weight: bold;">₹{{amount?dataMoney(amount):'0' }}</div>
         </div>
       </div>
       <div class="box-center">
          <div class="right-name"> New due date</div>
         <div class="center-right">
          <div style="font-size:0.64rem;color: #000000;font-weight: bold;">{{delayExpiryTime?Time(delayExpiryTime):'0' }}</div>
         </div>
       </div>
       <div class="box-show" v-show="showingImage">
         <div class="show-line"></div>
         <div class="show-title">
          <div class="title-left">
            Extension details
          </div>
          <!-- <img src="@/assets/rightOne.png" alt="" style="width: 0.16rem;height: 0.2667rem;margin-right:0.7467rem;" > -->
         </div>
         <div class="box-num" >
          <div class="numBox" v-for="(item,index) in list" :key="index">
            <div class="num-left">{{ item.strip }}</div>
            <div class="num-right"  v-html="item.increased"></div>
          </div>
        </div>
       </div>
       <!-- . -->
       <div class="box-btn" @click="pay" >
       Pay
       </div>
    </div>
    <div class="box-tip">
     <div class="tip-content">
        <div class="tips">1. After the UPI has expired, please re-enter the repayment </div>
        <div class="tip-wrap" style="margin-bottom: 0.08rem;">page to start the repayment and get a new UPI.</div>
        <div class="tips">2. If the status of your paid order has not modified, please </div>
        <div class="tip-wrap">provide the following proof of payment and we will handle</div>
        <div class="tip-wrap">  the payment for you.</div>
     </div>
    </div>
    <div class="box-from">
      <div class="title">Upload proof of payment</div>
    <div class="mian">
      <div class="userName">
        <van-form>
        <van-field
            v-model="userfrom.utr"
            class="field-phone"
            type="digit"
            placeholder="Enter UTR"
            autocomplete="off"
            @input="clickInput"
            />
          </van-form>
      </div>
       <van-form>
          <van-field
                v-model="userfrom.amount"
                class="field-vcode"
                autocomplete="off"
                left-icon=""
                type="digit"
                placeholder="Enter the exact amount of payment"
                @click="addCurrencySymbol"
                @input="inputCurrencySymbol"
                @blur="blurCurrencySymbol"
              >
           </van-field>
          </van-form>
     
    </div>
    <div class="title">Screenshot proof (optional)</div>
    <div class="bottomImg">
      <div class="Img" v-for="(item,index) in listImg" :key="index">
        <img :src="item" alt="" style=" width:2.56rem;height:2.56rem;">
        <img src="@/assets/off.png" alt="" class="offImg" @click="delImg(index)">
      </div>
      <div class="addImg" @click="addImg" v-if="listImg.length<3"> 
        <img src="@/assets/addImg.png" alt="" style="height: 0.4533rem;width:0.5333rem;margin-bottom:0.2667rem;">
        <div style="font-size:0.3733rem;color: #AAB0BF;">Add pictures </div>
      </div>
      
      <!-- <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <img src="@/assets/addImg.png" alt="" >
      </div>
      <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <div style="font-size:0.3733rem;color: #AAB0BF;">Add pictures </div>
      </div> -->
    </div>
    <van-button type="primary" class="bottom-btn"  :disabled="!userfrom.utr||!userfrom.amount" @click="Submit">Submit</van-button>
    </div>
    
    <agreeMent></agreeMent>
    </div>
  
    
    
  </div>
</template>

<script>
import { Toast } from 'vant';
import { dataMoney } from '@/utils/money'
import { Time } from '@/utils/time'
import { base64ToImage } from '@/utils/baseImg'
import {getOrderDetails,getDelayFlexble,getRepaymentDetail} from '@/views/repaymentExtension/lib.js'
import {getUpOrderUtr} from '@/http/repayment'
import agreeMent from '@/components/agreeMent.vue'
export default {
name:'normalExtension',
components:{agreeMent},
data(){
 return{
  checked:false,
  isListVisible:false,
  showingImage:false,
  productName:null,
  delayExpiryTime:null,
  loadingBtn:false,
  loading:true,
  amount:null,
  orderNo:localStorage.getItem('orderNo')||'',
  img:null,
  title:'normalExtension',
  userfrom: {
        utr: '',
        amount: '',
      },
      listImg:[],
    list:[ ]
 }
},
watch:{
  'userfrom.amount': {
      handler (ne, old) {
        if (ne.length >= old.length&&ne!=='₹') {
          const amount = this.userfrom.amount.replace(/₹/g, '');
      this.$managementEvents({ pageName: 'parish', action: 'concede', extInfo: {  amount: amount} })
        }
      }
    }
},
methods: {
  dataMoney,
  Time,
  //订单详情
 async getOrderDetails(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...', 
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
   const res=await getOrderDetails({
      pageType:2,
      orderNo:this.orderNo 
   })
   console.log(res,'逾期订单详情');
 if(res.arrival==='200'){
  loadingInstance.clear()
  this.loading=false
   this.productName=res.brake.midnight
   this.img = base64ToImage(`${'data:image/png;base64,' + res.brake.beat}`)
 }
  },
  //展期订单
 async getDelayFlexble(){
   const res=await getDelayFlexble({
    delayTerm: 2,
    orderNo:this.orderNo, 
    termUnit: 2
   })
   console.log(res);
   this.amount=res.brake.contain
   this.delayExpiryTime=res.brake.homework
   this.list=res.brake.guilt
  },
 async pay(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...', 
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
  this.$managementEvents({ pageName: 'parish', action: 'coordinate', extInfo: '' })
  if (this.loadingBtn) return
      this.loadingBtn = true
    const res=await getRepaymentDetail({
      delayTerm: 2,
      orderNo:this.orderNo,
      requestType: 2
     })
     console.log(res);
     loadingInstance.clear()
     this.$router.push({ name: 'easyPay', params: { url:res.brake.apparent[0]?.example,name:'repaymentExtension' } })
     setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
  },
  go(){
    this.$javaGet('returnH5')
  },
  showBox(){
    this.isListVisible = !this.isListVisible
      this.showingImage = !this.showingImage
  },

    
    addCurrencySymbol() {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (this.userfrom.amount === '') {
        this.userfrom.amount = '₹';
      }
    },
    clickInput(){
      this.$managementEvents({ pageName: 'parish', action: 'factor', extInfo: {  utr: this.userfrom.utr,name:'repaymentExtension'} })
    },
    inputCurrencySymbol(value) {
    
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (value === '') {
        this.userfrom.amount = '₹';
      }
      if (value && value.charAt(0) !== '₹') {
        this.userfrom.amount = '₹' + value;
      }
    },
    blurCurrencySymbol() {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
        if( this.userfrom.amount ==='₹'){
          this.userfrom.amount = '';
      
        }
       
    },
    delImg(index){
      console.log(index);
      this.listImg.splice(index, 1);
    },
    addImg(){
      // 调取ios方法获取图片
      this.$managementEvents({ pageName: 'parish', action: 'horn', extInfo: '' })
      this.$javaGet('backImg',(res)=>{
        if(this.listImg.length===2 && res[0].length>1){
          Toast('Only select up to 3 images')
        }else if(this.listImg.length===1 && res[0].length>2){
          Toast('Only select up to 3 images')
        }
        else if(this.listImg.length===0 && res[0].length>3){
          Toast('Only select up to 3 images')
        }else{
          res[0].forEach(item => {
      this.listImg.push(`data:image/png;base64,${item}`);
    });
        }
        this.$managementEvents({ pageName: 'parish', action: 'absorb', extInfo: {img: this.listImg} })
      })
     
    },
    dataURLtoFile(dataurl, filename) { 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    },

   async Submit(){
    const loadingInstance = Toast.loading(
        {
          message: 'Loading...', 
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    if (this.loadingBtn) return
      this.loadingBtn = true
    this.$managementEvents({ pageName: 'parish', action: 'employment', extInfo: '' })
      const amount = this.userfrom.amount.replace(/₹/g, '');
      const res=await getUpOrderUtr({
       orderNo:this.orderNo,
        utr:this.userfrom.utr,
        img:this.listImg.map(item => this.dataURLtoFile(item)),
        amount:amount
      })
      if(res.arrival==='200'){
        loadingInstance.clear()
        Toast('Submit Success')
        this.$managementEvents({ pageName: 'parish', action: 'hair', extInfo: '' })
        setTimeout(()=>{
          this.$javaGet('backHome')
        },500)
      }else{
        loadingInstance.clear()
      }
      setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
      console.log(res);
      console.log('点击了');
    },
    showLoan(){
      this.$refs.loanDlog.show=true
    }
  

  },
  mounted(){
    this.getOrderDetails()
    this.getDelayFlexble()
    this.$managementEvents({ pageName: 'parish', action: 'assure', extInfo: { loanOrderId: this.orderNo } })
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: rgba(0,0,0,0.4);
  // font-size: 28px;
}


.content{
  overflow: auto;
  height: 100%;
   background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  .headers{  
    background: #000000;
  // background-image: url("@/assets/homeBg.png");
    position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99999;
    width: 100%;
    height: 3.2rem;
     padding: 1.4133rem 0 0 0;
    .headers-title{
      width: 100%;
      height: 1.28rem;
      padding: 0 0.3467rem 0 0.64rem;
      display:flex;
      // justify-content:;
      align-items: center;
    }
  }
  .mian-box{
    width: 100%;
    height: auto;
    margin-top:3.4rem;
    background: #F6F7F5;
    display: flex;
    padding:0.5333rem 0 1.0933rem 0;
    flex-direction: column;
    align-items: center;
    border-radius: 1.0667rem 1.0667rem 0 0;
  .box-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 9.1467rem;
    height: auto;
    padding: 0.64rem 0.48rem;
    border-radius:0.5333rem;
    background: #FFFFFF;
    .box-title{
      width: 100%;
      margin-bottom: 0.5867rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        display: flex;
        align-items: center;
        
      }
      .title-right{
        display: flex;
        align-items: center;
      }
    }
    .box-center{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-name{
          font-size: 0.4267rem;
          color: #a2a3a6;
        }
      .center-right{
        display: flex;
        align-items: center;
      }
    }
    .box-show{
      margin-top: 0.48rem;
       display: flex;
       flex-direction: column;
       .show-line{
        width: 8.2933rem;
        height: 0.0267rem;
        background: #E7E7E7 ;
        margin-bottom: 0.56rem;
       }
       .show-title{
        display: flex;
        justify-content: space-between;
        .title-left{
           font-size: 0.3733rem;
           color: #000000;
        }
       }
       .box-num{
        display: flex;
        margin-top: 0.3467rem;
        flex-direction: column;
        .numBox{
          display: flex;
          justify-content: space-between;
         
          margin-bottom: 0.4267rem;
          .num-left{
            font-size: 0.3733rem;
            color: #8F939B;
          }
          .num-right{
            font-size: 0.3733rem;
            color: #000000;
            font-weight: 600;
          }
        }
      }
    }
    .box-btn{
      margin-top: 0.5333rem;
      width:6.6933rem;
      height: 1.5733rem;
      background: linear-gradient( 135deg, #7423CF 0%, #B52CB7 100%);
      box-shadow: 0 0.16rem 0.6667rem 0 rgba(174,43,186,0.5);
      border-radius: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.48rem;
      font-weight: 700;
      color: #ffffff;
    }

  }
  .box-tip{
    width: 9.1467rem;
    padding: 0.3467rem 0.4267rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.3733rem;
    padding: 0.2933rem 0.3rem 0.2933rem 0.3rem;
    height: 3.04rem;
    text-align: initial;
    background: #FBEEED;
    border-radius: 0.6667rem;
    margin-bottom: 0.4rem;

      .tips{
        width: 100%;
        font-size: 0.32rem;
        color: #F2513E ;
      
      }
      .tip-wrap{
        width: 100%;
        font-size: 0.32rem;
        color: #F2513E;
        
      }
    
  }
  .box-from{
    padding: 0.6133rem 0.4267rem;
    width: 9.1467rem;
    height: auto;
    background: #ffffff;
    border-radius: 0.64rem;
    margin-bottom: 0.56rem;
    .title{
    width: 100%;
    margin-bottom: 0.4267rem;
    text-align: initial;
    color: #000000;
    font-weight: bold;
    font-size: 0.48rem;
  }
  ::v-deep  .van-field__control{
  font-size: 0.4267rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  color: #000000 !important;
}

  .mian{
      .userName{
        display: flex;
        margin-bottom: 0.2667rem;
             .field-phone{
              width: 8.2667rem;
              height: 1.36rem;
              margin-bottom: 0.1733rem;
              border-radius: 0.32rem;
              display: flex;
              align-items: center;
              background: #F1F3F0;
             }
      }
      .field-vcode{
          width:8.2667rem;
          height: 1.36rem;
          border-radius: 0.32rem;
          display: flex;
          align-items: center;
          background: #F1F3F0;
          margin-bottom: 0.8533rem;
      }

  }
  .bottomImg{
    width: 100%;
    display: flex;
    flex: 1;
    // height: ;
    margin-bottom:0.7733rem;
    // justify-content:space-between;
    // padding: 0 0.32rem;
    .addImg{
      // margin-right: 0.5333rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 2.56rem;
      height: 2.56rem;
      background: #F1F3F0;
      border-radius: 0.64rem;
    }
    .Img{
      margin-right:0.2933rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.56rem;
      height: 2.56rem;
      border-radius: 0.64rem;
      position: relative;
      .offImg{
        width: 0.3733rem;
        height: 0.3733rem;
        position: absolute;
        top: 0.1333rem;
        right: 0.1333rem;
      }
    }
    // justify-content: ;
  } 
  .bottom-btn{
    width:7.7067rem;
    height:1.5733rem;
    background: #005AF1;
    border-radius:0.8rem;
    color: #ffffff;
    box-shadow: 0 0.16rem 0.64rem 0 rgba(0,90,241,0.5);
    font-size: 0.48rem;
    font-weight: 700;
    border: none;
  }
  .bottom-btn:disabled{
      opacity: 0.9; /* 设置禁用状态下的透明度 */
      background: #A3A9B3;
      color: #FFFFFF;
      box-shadow:initial;
    }
  }
 
 
  }


}

</style>