import Request, { API } from '@/http/'
export function managementEvents (data) {
  Request.getData(API.managementEvents + `${data.pageName}/${data.action}`, {
    confess: localStorage.getItem('adid') || '',
    tag: localStorage.getItem('userRandomKey') || '',
    unit: data.extInfo ? JSON.stringify(data.extInfo) : undefined
  })
}

export const $managementEvents = managementEvents
export default {
  install (Vue, options) {
    console.log(options);
    Vue.prototype.$managementEvents = managementEvents
  }
}
