import Request, { API } from '@/http/index.js'

// 是否展示自动复借按钮
export async function getAutoApply () {
  const res = await Request.getData(API.order.AutoApply)
  return res
}
//是否自动复借
export async function getSettledAutoApply (data) {
  const res = await Request.getData(API.order.SettledAutoApply+ `?housing=${data.orderNo}&strange=${data.type}`)
  return res
}
//还款详情
export async function getRepaymentDetail (data) {
  const res = await Request.getData(API.order.RepaymentDetail,{
    rather: data.delayTerm,
    housing: data.orderNo,
    can:data.requestType
  })
  return res
}
//更新订单utr接口
export async function getUpOrderUtr (data) {
  const res = await Request.getData(API.order.UpOrderUtr+ `?orderNo=${data.orderNo}&utr=${data.utr}&amount=${data.amount}&img=${data.img}`)
  return res
}
//获取银行卡信息
export async function getBankCard () {
  const res = await Request.getData(API.order.BankCard)
  return res
}