import request  from './axios'
export async function getUpOrderUtr (data) {
  const formDate = new FormData()
  if (data.img) {
    for (let index = 0; index < data.img.length; index++) {
      const file = data.img[index]
      formDate.append('img', file)
    }
  }
console.log('到接口来了吗');
console.log(data,'data');
  return request({
    url   : `/governor/integration/cart`,
    method: 'POST',
    params: {
      housing: data.orderNo,
      listener: data.utr,
      contain: data.amount,
    },
    data:data.img && data.img.length ? formDate : undefined,
    headers: { 'Content-Type': 'multipart/form-data' },
  })


}