export function dataMoney (value) {
  const money = value.toString()
  if (money && typeof money === 'string') {
    // 匹配整数部分和小数部分，包括正负号
    const regex = /^(-?\d+)(\.\d+)?$/
    const matches = money.match(regex)
    if (matches) {
      let integerPart = matches[1]
      let decimalPart = matches[2] || '' // 如果有小数部分则使用，否则为空字符串

      // 使用正则表达式在整数部分添加千位分隔符
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      // 小数部分保留两位
      if (decimalPart) {
        decimalPart = decimalPart.substring(0, 3) // 保留两位小数
      }

      return decimalPart ? `${integerPart}${decimalPart}` : integerPart
    }
  }
  return money
}
