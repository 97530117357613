<template>
  <div class="content">
    <div class="headers">
       <img src="@/assets/left.png" alt="" style="width:0.2133rem;height:0.3733rem;margin-left:0.64rem;" @click="go">
    </div>
    <div style="width:100%; height:100vh;margin-top: 3.2rem;">
      <iframe :src="url" frameborder="0" width="100%" height="100%" id="myIframe"></iframe>
    </div> 
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
name:'easyPay',
data(){
 return{
  url:'',
  name:'',
 }
},
methods: {
  go(){
    this.$router.go(-1)
  }
 
  },
  mounted(){
    this.url=this.$route.params.url
    this.name=this.$route.params.name
    const iframe = document.getElementById('myIframe');
    const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
  iframe.addEventListener('load', function() {
  // 当iframe加载完成时触发此逻辑
  loadingInstance.clear()
  // 在这里可以执行其他操作，表示iframe已经加载完成
});
if( this.name==='normalExtension'){
      this.$managementEvents({ pageName: 'deposit', action: 'assure', extInfo: {  type: 'Total', url: this.url } })
    }else if(this.name==='repaymentExtension'){
      this.$managementEvents({ pageName: 'deposit', action: 'assure', extInfo: {  type: 'Delay', url: this.url } })
    }
  }
}
</script>

<style lang="less" scoped>


.content{
  // overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  .headers{
    position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
    background-color:#0A0A0A;
    width: 100%;
    height: 3.2rem;
    padding: 1rem 0 0 0;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.1333rem;

  }
  
 

}

</style>