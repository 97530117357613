<template>
  <div class="box">
    <headers :title="title" ref="headers"></headers>
    <div style="width: 10rem; height:100vh;">
      <meta charset="UTF-8">
      <iframe :src="url" frameborder="0" width="100%" height="100vh" ></iframe>
    </div>
  </div>
</template>

<script>
import headers from './components/header.vue'
export default {
  name: 'agreeMent',
  components: { headers },

  data () {
    return {
      title: '',
      url: '',
      count: 0,
      show: null,
      time: null
    }
  },
  methods: {

  },
 
  mounted () {
    window.scrollTo(0, 0)
    console.log(this.$route.query, 'this.$route.query.name')
    switch (this.$route.query.name) {
      case 'Acuerdo de Servicio':
        // 注册协议
        console.log('进来了')
        this.title = 'Acuerdo de Servicio'
        this.url = `${window.location.protocol}//${window.location.host}/contract/register.html`
        break
      case 'Privacy Agreement':
        // 隐私协议
        console.log('进来了')
        this.title = 'Privacy Agreement'
        this.url =`${window.location.protocol}//${window.location.host}/professional/handful`
        this.$managementEvents({ pageName: 'when', action: 'assure', extInfo: {  type: 'Privacy'} })
        break
      case 'Acuerdo de préstamo':
        // 贷款协议
        this.title = 'Loan Agreement'
        this.url = `${window.location.protocol}//${window.location.host}/professional/insect`
        this.$managementEvents({ pageName: 'when', action: 'assure', extInfo: {  type: 'Loan'} })

        break
      default:
        // 执行默认操作
        this.title = 'Acuerdo de préstamo'
        // this.url = `${window.location.protocol}//${window.location.host}/contract/loan.html`
        this.url = `${window.location.protocol}//${window.location.host}/contract/loan.html`
    }
    console.log('到这一步')
  }

}
</script>
<style lang="less" scoped>
 body {
    font-family: Arial, sans-serif; /* 使用通用字体 */
  }
  .box{
    min-height: 100vh;
  }
</style>
