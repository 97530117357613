export function Time (str) {
  const timer = new Date(parseInt(str))
  const year = timer.getFullYear() // 转换成年
  let month = timer.getMonth() + 1 // 转换成月 ，月注意要加1，因为外国月份是0 - 11月
  let day = timer.getDate() // 转换成日
  month = month >= 10 ? month : '0' + month
  day = day >= 10 ? day : '0' + day
  const strs = `${day}-${month}-${year}`
  return strs
}
export function formatTimestamp (timestamp) {
  const date = new Date(timestamp)

  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }

  const dateString = date.toLocaleDateString('es-ES', options)

  const hour = date.getHours()
  const minute = date.getMinutes()
  const period = hour >= 12 ? 'PM' : 'AM'
  const formattedHour = (hour % 12 || 12).toString().padStart(2, '0')
  const formattedMinute = minute.toString().padStart(2, '0')

  const formattedTime = `${formattedHour}:${formattedMinute} ${period}`

  const formattedDate = `${dateString}, a las ${formattedTime}`

  return formattedDate
}
